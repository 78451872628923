<script
  setup
  lang="ts"
  generic="T extends Exclude<keyof GA4Entities, 'cartList' & 'gaItem'>"
>
import { provide } from "vue"
import { GA4Entities } from "nuxt-ga4"

const props = withDefaults(
  defineProps<{
    id: string
    name: string
    entity: T
    entityId?: string
    payload?: GA4Entities[T]
  }>(),
  {
    id: "plp",
    entityId: ""
  }
)

const { id, name, entity, entityId, payload } = toRefs(props)

const payloadList = computed<GA4Entities[T]>(() => {
  return {
    list_id: id.value,
    list_name: name.value,
    ...(payload?.value ?? {})
  }
})

const statefulEntityList = useGAEntity(entity.value || entityId.value)
statefulEntityList.value = payloadList?.value ?? {}

provide("statefulEntityList", statefulEntityList)
</script>

<template>
  <slot />
</template>
